// import React from 'react';
// import VideoPlayer from 'react-video-js-player';

// import './index.css';

// const HoldingVideo = ({ srcData }) => {
//   console.log('src data', srcData)
//   return (
//     <div className='holding-video-container'>
//       <VideoPlayer
//         className='holding-video'
//         autoplay={'true'}
//         // poster='video/posterHoldingVideo.png'
//         src={srcData}
//         hideControls={['playbackrates', 'fullscreen', 'timer']}
//       />
//     </div>
//   );
// };

// export default HoldingVideo;


// import React, { useRef, useEffect } from 'react';
// import videojs from 'video.js';
// // import 'video.js/dist/video-js.css';
// import './index.css';

// const VideoPlayer = ({ srcData, poster }) => {
//   const videoRef = useRef(null);
//   const playerRef = useRef(null);

//   useEffect(() => {
//     // Initialize Video.js player only once
//     if (!playerRef.current) {
//       playerRef.current = videojs(videoRef.current, {
//         autoplay: true,
//         controls: true,
//         muted: false,
//         playsinline: true,
//         preload: 'auto',
//         poster: poster,
//         sources: [{
//           src: srcData,
//           type: 'video/mp4'
//         }]
//       }, function onPlayerReady() {
//         console.log('Video.js player is ready');
//       });
//     } else {
//       // If player is already initialized, update the source if it changes.
//       playerRef.current.src({ src: srcData, type: 'video/mp4' });
//     }

//     // Cleanup on unmount
//     return () => {
//       if (playerRef.current) {
//         playerRef.current.dispose();
//         playerRef.current = null;
//       }
//     };
//   }, [srcData, poster]);

//   return (
//     <div className='holding-video-container'>
//     <div data-vjs-player >
//       <video ref={videoRef} className="video-js vjs-default-skin holding-video" />
//     </div>
//     </div>
//   );
// };

// export default VideoPlayer;

// import React, { useEffect } from 'react';
// import videojs from 'video.js';
// import 'video.js/dist/video-js.css';
// import './index.css';

// const VideoPlayer = ({ srcData, poster, playerId = 'my-video-player' }) => {
//   useEffect(() => {
//     // Select the video element using its ID
//     const videoElement = document.getElementById(playerId);
//     if (!videoElement) return;

//     // Initialize the Video.js player on the selected element
//     const player = videojs(videoElement, {
//       autoplay: true,
//       controls: true,
//       muted: true,
//       playsinline: true,
//       preload: 'auto',
//       poster: poster,
//       sources: [
//         {
//           src: srcData,
//           type: 'video/mp4'
//         }
//       ]
//     }, function onPlayerReady() {
//       console.log('Video.js player is ready');
//     });

//     // Cleanup the player when the component unmounts
//     return () => {
//       if (player) {
//         player.dispose();
//       }
//     };
//   }, [srcData, poster, playerId]);

//   return (
//     <div data-vjs-player className="ß">
//       <video id={playerId} className="video-js vjs-default-skin holding-video" />
//     </div>
//   );
// };

// export default VideoPlayer;


// import React, { useEffect } from 'react';
// import videojs from 'video.js';
// // Note: We are not importing any external CSS files here.

// const VideoPlayer = ({ srcData, poster, playerId = 'my-video-player' }) => {
//   useEffect(() => {
//     const videoElement = document.getElementById(playerId);
//     if (!videoElement) return;

//     const player = videojs(videoElement, {
//       autoplay: true,
//       controls: true,
//       muted: true,
//       playsinline: true,
//       preload: 'auto',
//       poster: poster,
//       sources: [
//         {
//           src: srcData,
//           type: 'video/mp4'
//         }
//       ]
//     }, function onPlayerReady() {
//       console.log('Video.js player is ready');
//     });

//     return () => {
//       if (player) {
//         player.dispose();
//       }
//     };
//   }, [srcData, poster, playerId]);

//   // Inline styles for the container and video element
//   const containerStyle = {
//     position: 'relative',
//     width: '100%',
//     maxWidth: '800px', // Adjust as needed
//     margin: '0 auto',
//     backgroundColor: '#000',
//     overflow: 'hidden'
//   };

//   const videoStyle = {
//     width: '100%',
//     height: 'auto',
//     display: 'block'
//   };

//   return (
//     <div data-vjs-player style={containerStyle}>
//       <video id={playerId} style={videoStyle} />
//     </div>
//   );
// };

// export default VideoPlayer;

import React, { useRef, useEffect } from 'react';

const VideoPlayer = ({ srcData }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.muted = true;
      video.play().then(() => {
        setTimeout(() => {
          video.muted = false;
          console.log('Video unmuted after autoplay');
        }, 1000); 
      }).catch((error) => {
        console.error('Autoplay failed:', error);
      });
    }
  }, []);

  return (
    <video
      ref={videoRef}
      src={srcData}
      controls
      autoPlay
      controlsList="nodownload"
      style={{ width: '100%', maxWidth: '800px', display: 'block' }}
    />
  );
};

export default VideoPlayer;
