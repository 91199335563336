import React, { useState, lazy, Suspense } from 'react';
import RegistrationUserState from '../context/registeredUser/registeredUserState.js';
import { Dialog, CssBaseline, Container, Grid, Box, Paper, Typography, Slide } from '@material-ui/core';
import moment from "moment/moment";
import momentTz from "moment-timezone";
// Components
import HeaderBar from '../components/header/HeaderBar';
import BackgroundImg from '../components/UI/BackgroundImg';
import RegistrationEmail from './RegistrationEmail';

import './index.css';
import './RegistrationEmail.css';
import { getFullDate } from '../helpers/index.jsx';

const Logo = lazy(() => import("../components/UI/Logo"));

function isLogoTypeWebsite(logo) {
  return logo.type === 'website';
}

// Custom Slide Transition for Dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RegistrationContainer = ({ event, match, loading, saveAuth }) => {
  const [open, setOpenDialog] = useState(true);
  const [isHeader, setIsHeader] = useState(false);

  let brandingFound = false;
  let logoPadding = '';
  let logoPath = '';
  let headerTitle = '';

  let headerBackground = '#ffffff';
  if (event.items[0].webcast.colour.background !== '') {
    headerBackground = event.items[0].webcast.colour.background;
  }

  if (loading || event.length === 0) {
    // Optionally, you could add a loader here.
  } else {
    const websiteLogo = event.items[0].companies[0].logos.find(isLogoTypeWebsite);
    logoPath = websiteLogo ? websiteLogo.upload.path : null;

    event.items[0].webcast.branding.fonts.forEach((font) => {
      if (font.fontLinkHref !== '') brandingFound = true;
    });

    if (event.items[0].webcast.branding.logo) {
      logoPadding =
        event.items[0].webcast.branding.logo.padding !== ''
          ? event.items[0].webcast.branding.logo.padding
          : '10px';
    } else {
      logoPadding = '10px';
    }

    headerTitle = event.items[0].headerTitle
      ? event.items[0].headerTitle
      : event.items[0].title;
  }

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenDialog(false);
    }
  };

  return (
    <>
      {event.items[0].holdingSlide !== null ? (
        <BackgroundImg
          path={event.items[0].holdingSlide.path}
          img={true}
          colour={null}
        />
      ) : event.items[0].webcast.colour.background !== null ? (
        <BackgroundImg
          path={null}
          img={false}
          colour={event.items[0].webcast.colour.background}
        />
      ) : (
        <></>
      )}
      <CssBaseline />



      {event?.items[0]?.webcast?.zoom?.enable ?
        <Container maxWidth="xl" className="mainContainer" style={{ width: '80vw' }}>

          <Grid item xs={12} id="firstRow" className="whiteBg">
            {isHeader && (
              <HeaderBar
                eventId={match.match.params.eventId}
                eventHeaderTitle={headerTitle}
                logoPath={logoPath}
                logoPadding={logoPadding}
                headerBackground={headerBackground}
                brandingFound={brandingFound}
              />
            )}
          </Grid>
          <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            aria-labelledby="form-dialog-title"
            disableEscapeKeyDown
            maxWidth="lg"
            fullWidth
            PaperProps={{
              style: {
                borderRadius: '10px',
                padding: '20px',
                background: 'linear-gradient(145deg, #ffffff, #f5faff)',
                boxShadow: '0 14px 50px rgba(0,0,0,0.25)',
                animation: 'fadeIn 0.5s ease-out',
              },
            }}
          >
            <div
              className="responsiveContainer"
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'stretch',
                justifyContent: 'center',
                gap: '1rem',
              }}
            >
              {/* Left: Branding Section */}
              <Paper
                elevation={4}
                style={{
                  flex: '1 1 350px',
                  padding: '2rem',
                  color: 'black',
                  borderRadius: '10px',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'transform 0.3s ease',
                }}
                className="boxContainer creativePaper"
                onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.02)')}
                onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              >
                <Box>
                  <Box mb={2} className='showLogo'>
                    <Suspense fallback={<></>}>
                      {logoPath && (
                        <Logo
                          src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${logoPath}`}
                          title="headerTitle"
                          logoPadding={logoPadding}
                          backgroundColor="#fff"
                        />
                      )}
                    </Suspense>
                  </Box>
          
                  <Typography className="title branding-title">
                    {event.items[0]?.title}
                  </Typography>
                  <Typography variant="subtitle1" className="description branding-title-upcoming" style={{ marginTop: '0.5rem' }}>
                    {/* {getFullDate('2025-03-15T15:50:00.000Z')} */}

                    {
                           moment(event?.items[0]?.releaseTime).format(
                            `${event?.items[0]?.dateFormat}` 
                          )
                    }
                  </Typography>
                  <Box mt={2}>
                    <Typography style={{fontWeight:'bold', color:'black'}} variant="body1" className="description branding-title-upcoming" gutterBottom>
                      Please note, you will join via a Zoom Webinar, where you are able to watch the session and use the raised hand function to participate in Q&amp;A.
                    </Typography>
                    <Typography style={{fontWeight:'bold',color:'black'}}  variant="body1" className="description branding-title-upcoming" gutterBottom>
                      If you choose the dial in option please add your phone number. Unrecognised numbers will be removed from the call.
                    </Typography>
                    </Box>
              
                </Box>
                {/* Sticky Footer Section */}
                {/* <Box
                  style={{
                    background: '#f0f0f0',
                    padding: '1rem',
                    borderRadius: '12px',
                    marginTop: '1rem',
                  }}
                >
                  <Typography variant="body1">
                    This session will be recorded.
                  </Typography>
                </Box> */}
              </Paper>

              {/* Right: Registration Form */}
              <div
                className="boxContainer creativePaper"
                style={{
                  flex: '1 1 350px',
                  padding: '2rem',
                  background: '#ffffff',
                  borderRadius: '10px',
                  boxShadow: '0 8px 30px rgba(0,0,0,0.15)',
                  transition: 'transform 0.3s ease',
                }}
                onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.02)')}
                onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              >
                <Box mb={2} className='showLogoReverse'>
                  <Suspense fallback={<></>}>
                    {logoPath && (
                      <Logo
                        src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${logoPath}`}
                        title="headerTitle"
                        logoPadding={logoPadding}
                        backgroundColor="#fff"
                      />
                    )}
                  </Suspense>
                </Box>
                <RegistrationUserState>
                  <RegistrationEmail
                    match={match}
                    event={event}
                    saveAuth={false}
                    loading={loading}
                  />
                </RegistrationUserState>
              </div>
            </div>
          </Dialog>


        </Container> :
        <Container maxWidth='xl' className='mainContainer'>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            id='firstRow'
            className='whiteBg'
          >
            {
              isHeader &&
              <HeaderBar
                eventId={match.match.params.eventId}
                eventHeaderTitle={headerTitle}
                logoPath={logoPath}
                logoPadding={logoPadding}
                headerBackground={headerBackground}
                brandingFound={brandingFound}
              />
            }
          </Grid>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='form-dialog-title'
            disableEscapeKeyDown
          >

            {
              event.items[0]._id !== '6617f9d68d18254c64eac445' &&
              <Box style={{ display: 'flex', justifyContent: 'center' }}>

                <Suspense fallback={<></>}>
                  {logoPath && (
                    <Logo
                      src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${logoPath}`}
                      title={'headerTitle'}
                      logoPadding={logoPadding}
                      backgroundColor="#fff"
                    // backgroundColor={logoBackground}
                    />
                  )}
                </Suspense>
              </Box>

            }


            <RegistrationUserState>
              <RegistrationEmail
                match={match}
                event={event}
                saveAuth={false}
                loading={loading}
              />
            </RegistrationUserState>
          </Dialog>
        </Container>



      }

    </>
  );
};

const areEqual = (prevProps, nextProps) => prevProps.event === nextProps.event;

export default React.memo(RegistrationContainer, areEqual);
